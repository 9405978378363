import React, { useContext, useEffect, useState } from "react";
import Style from "./index.module.css";
import { FaCopy } from "react-icons/fa"; // Import the copy icon
import { UserContext } from "../../Contexts/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authInstance from "../../instance/AuthInstance";

const Refferal = () => {
  const UserData = useContext(UserContext);
  const { User } = UserData;
  const userId = User._id;
  const [copied, SetCopied] = useState(false);
  const [ProductDetails, SetProductDetails] = useState([]);
  // console.log(User,"userdata from reffer");
  // console.log(User.ReferalCode,"fee");
  const refferalCode = User.ReferalCode;
  const handleCopy = () => {
    navigator.clipboard
      .writeText(refferalCode)
      .then(() => {
        SetCopied(true);
        toast.success("Referral code copied to clipboard!");
      })
      .catch((err) => {
        console.error("failed to copy", err);
        toast.error("Failed to copy referal code");
      });
  };
  useEffect(() => {
    try {
      authInstance
        .get(`/api/user/product//get_user_products/userId`)
        .then((response) => {
          console.log(response.data, "res");
          SetProductDetails(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div className={Style.refferalContainer}>
      <div>
        <h1>Invite Friends to DealnBuy</h1>
        <ul className={Style.descriptionList}>
          <li>
            <strong>Add products to sell:</strong> Post 5 products for sale on
            your account to receive a unique referral ID.
          </li>
          <li>
            <strong>Share referral ID:</strong> Share your referral ID with your
            friends and invite them to join our platform.
          </li>
          <li>
            <h4>Friend’s action:</h4>
            <ul>
              <li>Create an account on DealnBuy.</li>
              <li>
                Add your referral ID in the referral section after sign-up.
              </li>
              <li>Post 5 products for sale on their account.</li>
            </ul>
          </li>
          <li>
            <h4>System verification:</h4> Our system will validate that your
            referred friends have added 5 products and used your referral ID.
          </li>
          <li>
            <strong>Reward transfer:</strong> Once verified, ₹50 will be
            transferred to your bank account within 3 working days.
          </li>
          <li>
            <strong>Referral limit:</strong> You can refer up to 20 friends
            through this program.
          </li>
        </ul>

        <p className={Style.additionalNotes}>Additional notes:</p>
        <ul className={Style.additionalNotesList}>
          <li>The referral program is open to all users.</li>
          <li>
            We reserve the right to modify or terminate this referral program at
            any time.
          </li>
        </ul>

        {ProductDetails.length >= 5 ? (
          <div className={Style.refferalCodeSection}>
            <div className={Style.refferalHeader}>Your Referral Code</div>
            <div className={Style.codeSection}>
              {User.ReferalCode ? (
                <div className={Style.code}>{User.ReferalCode}</div>
              ) : (
                <div className={Style.code}>000000</div>
              )}

              <button className={Style.copyButton} onClick={handleCopy}>
                <FaCopy className={Style.copyIcon} />
                {copied ? "Copied!" : "Copy Referral Code"}
              </button>
            </div>
          </div>
        ) : (
          <div className={Style.refferalCodeSection}>
            <div className={Style.refferalHeader}>Your Referral Code</div>
            <div className={Style.codeSection} style={{ letterSpacing: "1px" }}>
              <div className={Style.elsecode} style={{ fontSize: "14px" }}>
                The referral code will only be displayed after the user has
                added <span style={{fontSize:"20px", fontWeight:"bold"}}>5 products.</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Refferal;
{
  /* <p>The referral code will only be displayed after the user has added 5 products.!</p> */
}
