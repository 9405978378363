import React, { useContext, useEffect, useState } from "react";
import Style from "./index.module.css";
import { UserContext } from "../../Contexts/UserContext";
import authInstance from "../../instance/AuthInstance";
import { Link } from "react-router-dom";

const Rewards = () => {
  const LoggedInUser = useContext(UserContext);
  const { User } = LoggedInUser;
  const [ReferalData, SetReferalData] = useState([]);

  // Check if User data is available
  const fetchData = async () => {
    if (!User || !User._id) {
      console.log("User data not available yet.");
      return;
    }

    try {
      // Corrected template literal usage
      const response = await authInstance.get(
        `/api/user/Reffer/get_refferals?userId=${User._id}`
      );
      console.log("response data :::::", response.data);
      SetReferalData(response.data);
    } catch (err) {
      console.error(
        "Error fetching data:",
        err.response ? err.response.data : err.message
      );
    }
  };

  useEffect(() => {
    if (User && User._id) {
      fetchData();
    }
  }, [User]); // Adding User as dependency to ensure it's available

 

  console.log(ReferalData, "ref");

  return (
    <div className={Style.outer_container}>
      <div className={Style.conatiner}>
        {ReferalData.map((singleData, index) => (
          <div className={Style.reward_container} key={index}>
            <div>{singleData.referre}</div>
            <div>{singleData.adCount} product Added</div>
            <div>{singleData.withdraw ? "Rewarded" : "Reward Pending"}</div>
            <div>{singleData.adCount >= 5 ? (
                  <Link to={`/rewardpage/${singleData.refferedId}`}>
                    <button>Get Reward</button>
                  </Link>
                ) : (
                  " "
                )}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Rewards;
