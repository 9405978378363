import React from 'react'
import Style from "./index.module.css"
import { useNavigate } from 'react-router-dom'


const HomeCarouselCard = ({ item }) => {
    const navigate = useNavigate()
    
    
    return (
        <div className={Style.box}>
            <div className={Style.img_Container} onClick={()=>navigate('/refferals')}>
                <img src={item?.image.url} alt='' style={{ maxHeight: '100%', maxWidth: '100%' }} />
            </div>
        </div>
    )
}

export default HomeCarouselCard