import React, { useState, useEffect } from "react";
import Style from './Style.module.css';
import Header from '../../../Components/AdminComponents/Header/Header';
import Sidebar from '../../../Components/AdminComponents/Sidebar/Sidebar';
import DataTable from '../../../Components/AdminComponents/DataTable/DataTable';
import adminInstance from "../../../instance/AdminInstance"; // Adjust this import based on your file structure
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';  // SweetAlert default styling

const ReferralRequests = () => {
  const [RewardList, SetRewardList] = useState([]);

  const columns = [
    {
      name: 'Account Holder Name',
      selector: (row) => row.accountHolder,
      sortable: true,
    },
    {
      name: 'Account Number',
      selector: (row) => row.accountNumber,
      sortable: true,
    },
    {
      name: 'IFSC',
      selector: (row) => row.IFSC,
      sortable: true,
    },
    {
      name: '',
      cell: (row) => (
        <div className={Style.optionsContainer}>
          <button className={Style.editButton} onClick={() => OpenModal(row._id)}>{row.withdrawStatus ? "Paid" : "Mark as Paid"}</button>
        </div>
      ),
      width: '200px'
    },
  ];

  // SweetAlert modal function with confirmation
  const OpenModal = (itemId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, mark as paid!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      customClass: {
        confirmButton: 'custom-confirm-button',  // Optional class
        cancelButton: 'custom-cancel-button',    // Optional class
      },
      buttonsStyling: false, // Disable SweetAlert's default styling to apply your custom styles
      // Inline styling for buttons
      didRender: () => {
        const confirmButton = Swal.getConfirmButton();
        const cancelButton = Swal.getCancelButton();
  
        // Applying inline styles to the confirm button
        Object.assign(confirmButton.style, {
          
          backgroundColor: 'green',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        fontWeight: 'bold',
         // Add space between confirm and cancel buttons
         
        });
  
        // Applying inline styles to the cancel button
        Object.assign(cancelButton.style, {
          backgroundColor: 'crimson',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
          fontWeight: 'bold',
          marginRight: '30px',
        });
  
        // Add hover effects if necessary
        confirmButton.addEventListener('mouseover', () => {
          confirmButton.style.backgroundColor = 'darkgreen';
        });
        confirmButton.addEventListener('mouseout', () => {
          confirmButton.style.backgroundColor = 'green';
        });
  
        cancelButton.addEventListener('mouseover', () => {
          cancelButton.style.backgroundColor = 'darkred';
        });
        cancelButton.addEventListener('mouseout', () => {
          cancelButton.style.backgroundColor = 'crimson';
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleTransfer(itemId);
        Swal.fire("Paid!", "The referral has been marked as paid.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "The referral is not marked as paid.", "error");
      }
    });
  };
  
  

  // PUT API function to mark referral as paid
  const handleTransfer = (itemId) => {
    try {
      adminInstance.put(`/api/super_admin/reffer/transfer_initiated?requestId=${itemId}`)
      .then((response) => {
        console.log("Transfer Initiated:", response.data);
        // Optionally, reload the reward list to reflect changes
        loadRewards();
      })
      .catch((error) => {
        console.error("Error initiating transfer:", error);
      });
    } catch (error) {
      console.error("Error in handleTransfer function:", error);
    }
  };

  // Function to load referral requests
  const loadRewards = () => {
    try {
      adminInstance.get("/api/super_admin/reffer/referral_request")
        .then((response) => {
          SetRewardList(response.data);
        })
        .catch((error) => {
          console.error("Error loading referral requests:", error);
        });
    } catch (error) {
      console.error("Error in loadRewards function:", error);
    }
  };

  // Fetch referral requests on component mount
  useEffect(() => {
    loadRewards();
  }, []);
console.log(RewardList.withdrawStatus);
console.log(RewardList);


  return (
    <div className={Style.list}>
      <Sidebar />
      <div className={Style.listContainer}>
        <Header />
        <DataTable 
          Row={RewardList} 
          Columns={columns} 
          Title="Reward List" 
        />
      </div>
    </div>
  );
};

export default ReferralRequests;
