import React from 'react'
import RewardForm from '../../../Components/RewardForm/RewardForm'
import { useParams } from 'react-router-dom'

const RewardFormPage = () => {
  const {refferedId} = useParams()
  console.log(refferedId);
  
  return (
    <div>
      <RewardForm refferedId={refferedId}/>
    </div>
  )
}

export default RewardFormPage
