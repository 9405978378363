import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import instance from "../../instance/AxiosInstance";
import Style from "./index.module.css";
import { UserContext } from "../../Contexts/UserContext";
import { useTranslation } from "react-i18next";
import authInstance from "../../instance/AuthInstance";

const RewardForm = ({ refferedId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loggedInUser = useContext(UserContext);
  console.log(loggedInUser.User._id, "logged user");
  
  // Destructure userId correctly
  const userId = loggedInUser?.User?._id;
  console.log(userId,"userID");
  

  const [userData, setUserData] = useState({
    userId: userId,  // Ensure userId is defined
    reffered: refferedId,   // Use refferedId correctly
    accountNumber: "",
    ifsc: "",
    accountHolder: "",
  });

  console.log(userData,"usrdara");
  

  const [errors, setErrors] = useState({
    accountNumber: "",
    ifsc: "",
  });

  // Regex patterns for validation
  const accountNumberPattern = /^[0-9]{9,18}$/;
  const ifscPattern = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;

  const validateForm = () => {
    const errorsObj = {};
    if (!accountNumberPattern.test(userData.accountNumber)) {
      errorsObj.accountNumber =
        "Invalid account number. It should be 9-18 digits.";
    }
    if (!ifscPattern.test(userData.ifsc)) {
      errorsObj.ifsc = "Invalid IFSC Code. It should be 11 characters long.";
    }
    return errorsObj;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear the error when input changes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form data before submitting
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
        
      const response = await authInstance.post("/api/user/Reffer/referral_withdraw", {
        userId: userData.userId,  // Pass userId correctly
        reffered: userData.reffered,  // Pass refferedId correctly
        accountNumber: userData.accountNumber,
        ifsc: userData.ifsc,
        accountHolder: userData.accountHolder,
      });

      toast.success("Bank details added successfully!");
    //   navigate("/dashboard");
      console.log(response.data);
      
    } catch (error) {
      toast.error("Failed to add bank details.");
      console.error("Error:", error);
    }
  };

  return (
    <div className={Style.container}>
      <div className={Style.form_wrapper}>
        <div className={Style.form_container}>
          <div className={Style.otp_section}>
            <h1>{t("Add Account Details")}</h1> {/* Optional: Add translation for heading */}
            <form onSubmit={handleSubmit}>
              <div className={Style.input_div}>
                <label htmlFor="accountHolder">Full Name as in Bank</label>
                <input
                  type="text"
                  placeholder="Full Name"
                  id="accountHolder"
                  name="accountHolder"
                  value={userData.accountHolder}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className={Style.input_div}>
                <label htmlFor="accountNumber">Account Number</label>
                <input
                  type="text"
                  placeholder="Account Number"
                  id="accountNumber"
                  name="accountNumber"
                  value={userData.accountNumber}
                  onChange={handleChange}
                  required
                />
                {errors.accountNumber && (
                  <span className={Style.error}>{errors.accountNumber}</span>
                )}
              </div>

              <div className={Style.input_div}>
                <label htmlFor="ifscCode">IFSC Code</label>
                <input
                  type="text"
                  placeholder="IFSC Code"
                  id="ifsc"
                  name="ifsc"
                  value={userData.ifscCode}
                  onChange={handleChange}
                  required
                />
                {errors.ifscCode && (
                  <span className={Style.error}>{errors.ifscCode}</span>
                )}
              </div>

              <button type="submit">Submit</button>
            </form>
          </div>

          <div className={Style.right_section}>
            <div className={Style.img_wrapper}>
              <img src="/Images/undraw.svg" alt="Illustration" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardForm;
